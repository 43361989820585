@include component(container-panel) {
	border: 2px #f3f3f3 solid;
	padding: 10px;

	@include option(help){
    color: #1F1F1F;
    position: fixed;
    margin-left: 20px;
    margin-right: 20px;
  }
}

@include component(container-row) {
  float: right;
  margin-bottom: 10px;
  padding: 0 0px 0 10px;
}