.container-panel {
  border: 2px #f3f3f3 solid;
  padding: 10px; }
  .container-panel.\--help {
    color: #1F1F1F;
    position: fixed;
    margin-left: 20px;
    margin-right: 20px; }

.container-row {
  float: right;
  margin-bottom: 10px;
  padding: 0 0px 0 10px; }

.flash {
  background: #F6624A;
  color: white;
  width: 180px;
  position: fixed;
  right: 20px;
  top: 60px;
  padding: 1em;
  display: none; }

#loading_icon {
  display: none; }

.danger {
  color: red; }

/*# sourceMappingURL=app.css.map */
