@import 'csstyle';
@import 'components/container';
@import 'components/panel';
@import 'components/button';
@import 'components/flash';

#loading_icon {
	display: none;
}

.danger {
	color: red;
}
